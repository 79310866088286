import $ from 'jquery';
import Zuck from 'zuck.js';
import './style.scss';

$(document).ready(function () {
    const stories = $('#stories');

    if (stories.length && $(window).width() > 1024) {
        stories.niceScroll({
            cursorcolor: '#bdbdbd',
            cursorwidth: '5px',
            cursorborder: 'none',
            emulatetouch: true,
            cursordragontouch: true,
            scrollbarid: 'wp-story-scrollbar',
        });
    }

    const storyBody = $('body');

    const muteIcon =
        '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="#fff" d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z"/></svg>';
    const unMuteIcon =
        '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="#fff" d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z"/></svg>';

    const videoAutoMute = () => {
        $('.wp-story-premium-item').each(() => {
            const video = $(this).find('video');
            const button = $(this).find('.wp-story-muted');
            const viewer = $('.story-viewer');

            if (!video.prop('muted')) {
                button.html(unMuteIcon);
                viewer.removeClass('muted');

                setTimeout(() => {
                    button.hide();
                }, 1500);
            }
        });
    };

    const videoMute = () => {
        $('.wp-story-premium-item').each(() => {
            const video = $(this).find('video');
            const button = $(this).find('.wp-story-muted');
            const viewer = $('.story-viewer');
            video.prop('muted', false);
            button.html(unMuteIcon);
            viewer.removeClass('muted');

            setTimeout(() => {
                button.hide();
            }, 1500);
        });
    };

    const buildStory = function (data) {
        new Zuck('stories', {
            backNative: false,
            previousTap: true,
            autoFullScreen: stories.data('full-screen'),
            skin: stories.data('skin'),
            avatars: true,
            list: stories.data('list'),
            cubeEffect: stories.data('cube-effect'),
            openEffect: stories.data('open-effect'),
            paginationArrows: stories.data('arrows'),
            rtl: stories.data('rtl'),
            localStorage: true,
            stories: data,
            callbacks: {
                onOpen(storyId, callback) {
                    callback();
                    storyBody.addClass('wp-story');
                    $(window).scrollTop(0);
                },
                onClose(storyId, callback) {
                    callback();
                    storyBody.removeClass('wp-story');
                },
                onNavigateItem(storyId, nextStoryId, callback) {
                    callback();
                },
            },
            template: {
                viewerItemBody(index, currentIndex, item) {
                    const isPost = stories.data('post');
                    return `<div
                            class="item wp-story-premium-item bg-type-${
                                item.bg_type
                            } story-${item.v_h} ${
                        item.link ? 'linked-story' : ''
                    } ${item.seen === true ? 'seen' : ''} ${
                        currentIndex === index ? 'active' : ''
                    }" data-time="${item.time}" data-type="${
                        item.type
                    }" data-index="${index}" data-item-id="${item.id}"
                    ">                                                  
                            ${
                                item.type === 'video'
                                    ? `<video class="media" muted webkit-playsinline playsinline preload="auto" src="${item.src}" ${item.type}></video>
                                            <span class="tip muted wp-story-muted">${muteIcon}</span>`
                                    : `<img loading="auto" class="media" src="${item.src}" ${item.type} alt="Story Item"> `
                            }
                                        ${
                                            item.link
                                                ? `<a class="wp-story-premium-tip tip link" href="${
                                                      item.link
                                                  }" rel="noopener" target="${
                                                      item.newtab
                                                  }">
                                            ${
                                                !item.linkText ||
                                                item.linkText === ''
                                                    ? wpStoryObject.lang
                                                          .visitLink
                                                    : item.linkText
                                            }
                                            </a>`
                                                : ''
                                        }
                                        ${
                                            isPost
                                                ? `<a class="wp-story-post-title" href="${item.link}">${item.post_title}</a>`
                                                : ''
                                        }
                            </div>`;
                },
                timelineItem(item) {
                    const isPost = stories.data('post');
                    return `
            <div class="story ${item.seen === true ? 'seen' : ''}">
              <a class="item-link" href="${item.link}">
                <span class="item-preview wp-story-premium-preview">
                  <img lazy="eager" src="${item.photo}" alt="Story Item">
                </span>
                <span class="info" itemProp="author" itemScope itemType="http://schema.org/Person">
                  <strong class="name" itemProp="name">${
                      isPost ? item.items[0].post_title : item.name
                  }</strong>
                </span>
              </a>
              <ul class="items"></ul>
            </div>`;
                },
                viewerItem(item) {
                    const isPost = stories.data('post');
                    const isArrows = stories.data('arrows');
                    return `<div class="story-viewer">
                    <div class="head">
                      <div class="left">
                        <a class="back"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="#ffffff" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg></a>
                        <span class="item-preview">
                          <img lazy="eager" class="profilePhoto"
                          src="${
                              isPost ? item.items[0].author_avatar : item.photo
                          }" alt="Story Item"
                          />
                        </span>
                        <div class="info">
                          <strong class="name">${
                              isPost ? item.items[0].author_name : item.name
                          }</strong>
                          <span class="time">${item.timeAgo}</span>
                        </div>
                      </div>

                      <div class="right">
                        <span class="time">${item.timeAgo}</span>
                        <span class="loading"></span>
                        <a class="close" tabIndex="2"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="#ffffff" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg></a>
                      </div>
                    </div>

                    <div class="slides-pointers">
                      <div class="wrap"></div>
                    </div>

                    ${
                        isArrows
                            ? `<div class="slides-pagination">
                          <span class="previous"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="#ffffff" d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"/></svg></span>
                          <span class="next"><svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path fill="#ffffff" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"/></svg></span>
                        </div>`
                            : ''
                    }
                  </div>`;
                },
            },
            language: wpStoryObject.lang,
        });
    };

    if (stories.length) {
        fetch(
            `${wpStoryObject.homeUrl}/wp-json/wp-story/v1/box/${stories.data(
                'story-id'
            )}`
        )
            .then((res) => res.json())
            .then((data) => {
                buildStory(data);
            })
            .catch((err) => {
                throw err;
            });
    }

    if ($('#stories').length) {
        $(document).on('click', videoAutoMute);
    }

    $('body').on('click', '.wp-story-muted', videoMute);

    if ('false' !== wpStoryObject.opener) {
        $(wpStoryObject.opener).on('click', () => {
            $('#stories .story')[0].click();
        });
    }
});
